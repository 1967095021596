input[type="text"] {
  padding: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: bold;
  height: 40px;
}

button {
  background-color: #4b4f4b3b;
  border: none;
  color: black;
  font-size: 25px;
  font-weight: bolder;
  padding: 12px 30px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}

canvas {
  /*prevent interaction with the canvas*/
  pointer-events: none;
  border: 1px solid gray;
  background-color: #009CDE;
  font-family: "Jazz LET";
  letter-spacing: 2px !important;
}

p.a {
  white-space: nowrap;
  padding-left: 30px;
}

.wrongMessage {
  width: -webkit-fill-available;
  text-align: center;
  color: red;
}

.refreshCaptch {
  color: white !important;
  background: #009CDE;
  border-radius: 7px;
  margin-left: 5%;
  cursor: pointer;
}

.captchaMasterDiv {
  display: flex;
  width: 100%;
}

.captchaTextSpan {
  display: inline-block;
  /* width: 14%; */
  /* float: left; */
}

.captchTextAreaSpan {
  /* width: 33%; */
  float: left;
}

.captchRefreshSpan {
  float: left;
  margin-left: 1%;
  margin-right: 1%;
}