.maincontainer {
    margin: 2%;
    padding: 1%;
    width: -webkit-fill-available;
}

.layout {
    color: #545e66;
    width: 100%;
}

.textArea {
    height: 15vh !important;
}

.layout-menu {
    z-index: 1000;
}

.line {
    border-bottom: 1px solid #009CDE;
    padding-bottom: 20px;
    margin-left: 144px;
    margin-right: 133px;
    margin-top: 20px;
}

.btn-align {
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    margin-left: 20px;
}

.go-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 5px 0px;
}

.p-button-text-only {
    padding: 0 15px 0 15px;
}

.group-div {
    display: flex;
    flex-direction: row;
}

.p-progressbar-label {
    visibility: hidden;
}

.p-chips>ul.p-inputtext {
    display: inline-block;
    width: -webkit-fill-available;
}

.p-chips-input-token .p-inputtext {
    width: 20em;
}

textarea {
    padding: 5px;
    height: 40px;
    border-radius: 0.25em;
}

textarea::-webkit-input-placeholder {
    color: rgb(157, 169, 173);
}

.errormsg {
    width: inherit;
    text-align: center;
    color: red;
    font-weight: 700;
}

.errorPage {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center
}

.tryAgain {
    color: red;
    padding: 10px;
    font-style: oblique;
}

.clearclass {
    color: green;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
}

.table-responsive {
    overflow-x: unset !important;
}

body .p-button.p-button-success:enabled:focus {
    box-shadow: none;
}