.progressBars {
    height: 25px !important;
    width: 80vh;
    margin: auto;
    margin-bottom: 10px;
    /* border: 1px solid black; */
}

.progressBar {
    border: 2px solid whitesmoke;
}