@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
.p-datatable {
    margin-bottom: 1% !important;
    width: 80%;
    margin: auto;
}

body .p-datatable .p-datatable-thead>tr>th, body .p-datatable .p-datatable-tbody>tr>td, body .p-treetable .p-treetable-thead>tr>th, body .p-treetable .p-treetable-tbody>tr>td {
    text-align: left;
    border: 0px;
    border-right: 2px solid white;
}

.progress-Wrapper {
    display: flex;
    width: 55%;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    margin: auto;
    font-style: italic;
    font-size: medium;
}

.progress {
    width: 25%;
    margin: 0% 2% 0% 0%;
}

body .p-progressbar .p-progressbar-value {
    background: #009CDE;
}

.pipe {
    border-left: 2px solid #009CDE;
    height: 10px;
}

.status {
    text-align: center;
    color: #009CDE;
    font-weight: bold;
}

.visibile-text {
    color: #545e66;
}

body .p-chips>ul.p-inputtext .p-chips-token {
    background: #009CDE;
}

.p-col-d {
    display: table-cell;
}

.p-col-m {
    display: none;
}

@media screen and (max-width: 64em) {
    .p-col-d {
        display: none;
    }
    .p-col-m {
        display: inline-block;
    }
}

.progressStatus {
    /* border: 1px solid; */
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background: #A6ACAF;
    display: inline-block;
}

.statusReleased {
    background: green !important;
}

.statusDelayed {
    background: red;
}

.statusPickUp, .statusDeparted, .statusArrived {
    background: #009CDE !important;
    /* background: blue; */
}

.statusDefault {
    background: #A6ACAF !important;
}

.containerHeading {
    font-weight: bold;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    background-color: rgba(237, 239, 239, 1);
    font-size: 14px;
    padding: 6px 24px 6px 16px;
    line-height: 1.5rem;
}

.containerBody {
    border: none;
    background: white;
    padding: 10px;
}

.containerRows {
    color: #009CDE;
}

.datatable {
    border: none;
    /* max-width: 111vh; */
    overflow-x: auto;
    width: -webkit-fill-available;
    margin: auto;
}

.noRecords {
    height: 25vh;
    background: #EBEDEF;
    font-size: large !important;
    font-weight: 700 !important;
    text-align: center !important;
}
.noRecordsContainer{
    height: 10vh;
    background: #EBEDEF;
    font-size: large !important;
    font-weight: 700 !important;
    text-align: center !important;
}

.hyperlink {
    color: green;
    text-decoration: underline;
}