body {
  margin: 0 1% !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: -webkit-fill-available;
}

/* Select css starts here */

.MuiSelect-select.Mui-disabled {
  color: black !important;
}

.MuiSelect-selectMenu {
  height: 1.1875em !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  appearance: none;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08));
  display: block;
  height: 4rem;
  padding: 0 2.5rem 0 1.5rem;
  line-height: 3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  resize: none;
  min-height: 3rem;
  padding: 0 1rem;
  position: relative;
  background-color: #FFFFFF;
  border: 2px solid #C8CFD2;
  border-radius: 4px;
  color: #4A4E50;
  transition-duration: 100ms;
  transition-property: border-color, background-color, color, box-shadow;
}

.MuiSelect-outlined.MuiSelect-outlined:focus {
  outline-width: 0;
  border-color: #73AFC0;
  box-shadow: 0 0 0 3px #e3ecef;
}

/* Select css ends here */