@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
body {
  margin: 0 1% !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: -webkit-fill-available;
}

/* Select css starts here */

.MuiSelect-select.Mui-disabled {
  color: black !important;
}

.MuiSelect-selectMenu {
  height: 1.1875em !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  -webkit-appearance: none;
          appearance: none;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08));
  display: block;
  height: 4rem;
  padding: 0 2.5rem 0 1.5rem;
  line-height: 3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  resize: none;
  min-height: 3rem;
  padding: 0 1rem;
  position: relative;
  background-color: #FFFFFF;
  border: 2px solid #C8CFD2;
  border-radius: 4px;
  color: #4A4E50;
  transition-duration: 100ms;
  transition-property: border-color, background-color, color, box-shadow;
}

.MuiSelect-outlined.MuiSelect-outlined:focus {
  outline-width: 0;
  border-color: #73AFC0;
  box-shadow: 0 0 0 3px #e3ecef;
}

/* Select css ends here */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

input[type="text"] {
  padding: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: normal !important;
  height: 40px;
}

th {
  background-color: rgba(237, 239, 239, 1)
}

tbody {
  background-color: white;
}
.p-datatable {
    margin-bottom: 1% !important;
    width: 80%;
    margin: auto;
}

body .p-datatable .p-datatable-thead>tr>th, body .p-datatable .p-datatable-tbody>tr>td, body .p-treetable .p-treetable-thead>tr>th, body .p-treetable .p-treetable-tbody>tr>td {
    text-align: left;
    border: 0px;
    border-right: 2px solid white;
}

.progress-Wrapper {
    display: flex;
    width: 55%;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    margin: auto;
    font-style: italic;
    font-size: medium;
}

.progress {
    width: 25%;
    margin: 0% 2% 0% 0%;
}

body .p-progressbar .p-progressbar-value {
    background: #009CDE;
}

.pipe {
    border-left: 2px solid #009CDE;
    height: 10px;
}

.status {
    text-align: center;
    color: #009CDE;
    font-weight: bold;
}

.visibile-text {
    color: #545e66;
}

body .p-chips>ul.p-inputtext .p-chips-token {
    background: #009CDE;
}

.p-col-d {
    display: table-cell;
}

.p-col-m {
    display: none;
}

@media screen and (max-width: 64em) {
    .p-col-d {
        display: none;
    }
    .p-col-m {
        display: inline-block;
    }
}

.progressStatus {
    /* border: 1px solid; */
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background: #A6ACAF;
    display: inline-block;
}

.statusReleased {
    background: green !important;
}

.statusDelayed {
    background: red;
}

.statusPickUp, .statusDeparted, .statusArrived {
    background: #009CDE !important;
    /* background: blue; */
}

.statusDefault {
    background: #A6ACAF !important;
}

.containerHeading {
    font-weight: bold;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    background-color: rgba(237, 239, 239, 1);
    font-size: 14px;
    padding: 6px 24px 6px 16px;
    line-height: 1.5rem;
}

.containerBody {
    border: none;
    background: white;
    padding: 10px;
}

.containerRows {
    color: #009CDE;
}

.datatable {
    border: none;
    /* max-width: 111vh; */
    overflow-x: auto;
    width: -webkit-fill-available;
    margin: auto;
}

.noRecords {
    height: 25vh;
    background: #EBEDEF;
    font-size: large !important;
    font-weight: 700 !important;
    text-align: center !important;
}
.noRecordsContainer{
    height: 10vh;
    background: #EBEDEF;
    font-size: large !important;
    font-weight: 700 !important;
    text-align: center !important;
}

.hyperlink {
    color: green;
    text-decoration: underline;
}
.progressBars {
    height: 25px !important;
    width: 80vh;
    margin: auto;
    margin-bottom: 10px;
    /* border: 1px solid black; */
}

.progressBar {
    border: 2px solid whitesmoke;
}
.lastrow {
    border-bottom: 0px !important;
}

.linkbutton {
    color: #009CDE;
    padding-left: 1%;
    cursor: pointer;
}

.tableHeader {
    font-weight: bold !important;
}

.treetable {
    display: block !important;
    max-height: 18vh;
    overflow-y: auto;
    max-width: 100%;
}

.treetabletr, .treetablebody {
    display: table !important;
    width: 100%;
    table-layout: fixed;
}
input[type="text"] {
  padding: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: bold;
  height: 40px;
}

button {
  background-color: #4b4f4b3b;
  border: none;
  color: black;
  font-size: 25px;
  font-weight: bolder;
  padding: 12px 30px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}

canvas {
  /*prevent interaction with the canvas*/
  pointer-events: none;
  border: 1px solid gray;
  background-color: #009CDE;
  font-family: "Jazz LET";
  letter-spacing: 2px !important;
}

p.a {
  white-space: nowrap;
  padding-left: 30px;
}

.wrongMessage {
  width: -webkit-fill-available;
  text-align: center;
  color: red;
}

.refreshCaptch {
  color: white !important;
  background: #009CDE;
  border-radius: 7px;
  margin-left: 5%;
  cursor: pointer;
}

.captchaMasterDiv {
  display: flex;
  width: 100%;
}

.captchaTextSpan {
  display: inline-block;
  /* width: 14%; */
  /* float: left; */
}

.captchTextAreaSpan {
  /* width: 33%; */
  float: left;
}

.captchRefreshSpan {
  float: left;
  margin-left: 1%;
  margin-right: 1%;
}
.layout-menu {
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    border: 0;
    top: 0;
    border-bottom: 4px solid #009CDE;
    padding: 1%;
}

.menu-items {
    float: left;
    margin-left: 10px;
    text-decoration: none;
}
.maincontainer {
    margin: 2%;
    padding: 1%;
    width: -webkit-fill-available;
}

.layout {
    color: #545e66;
    width: 100%;
}

.textArea {
    height: 15vh !important;
}

.layout-menu {
    z-index: 1000;
}

.line {
    border-bottom: 1px solid #009CDE;
    padding-bottom: 20px;
    margin-left: 144px;
    margin-right: 133px;
    margin-top: 20px;
}

.btn-align {
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    margin-left: 20px;
}

.go-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 5px 0px;
}

.p-button-text-only {
    padding: 0 15px 0 15px;
}

.group-div {
    display: flex;
    flex-direction: row;
}

.p-progressbar-label {
    visibility: hidden;
}

.p-chips>ul.p-inputtext {
    display: inline-block;
    width: -webkit-fill-available;
}

.p-chips-input-token .p-inputtext {
    width: 20em;
}

textarea {
    padding: 5px;
    height: 40px;
    border-radius: 0.25em;
}

textarea::-webkit-input-placeholder {
    color: rgb(157, 169, 173);
}

.errormsg {
    width: inherit;
    text-align: center;
    color: red;
    font-weight: 700;
}

.errorPage {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center
}

.tryAgain {
    color: red;
    padding: 10px;
    font-style: oblique;
}

.clearclass {
    color: green;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
}

.table-responsive {
    overflow-x: unset !important;
}

body .p-button.p-button-success:enabled:focus {
    box-shadow: none;
}
