.lastrow {
    border-bottom: 0px !important;
}

.linkbutton {
    color: #009CDE;
    padding-left: 1%;
    cursor: pointer;
}

.tableHeader {
    font-weight: bold !important;
}

.treetable {
    display: block !important;
    max-height: 18vh;
    overflow-y: auto;
    max-width: 100%;
}

.treetabletr, .treetablebody {
    display: table !important;
    width: 100%;
    table-layout: fixed;
}