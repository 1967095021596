.layout-menu {
    overflow: hidden;
    position: sticky;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    border: 0;
    top: 0;
    border-bottom: 4px solid #009CDE;
    padding: 1%;
}

.menu-items {
    float: left;
    margin-left: 10px;
    text-decoration: none;
}